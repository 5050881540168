.edit_profile_name_input {
  width: 45% !important;
  background-color: #f4f4f4;
  border: 1px solid #575a5e !important;
  margin: 0px !important;
  color: #575a5e;
  text-align: center;
  font-family: Patua One;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.edit_email_input {
  width: 65% !important;
  background-color: #f4f4f4;
  border: 1px solid #575a5e !important;
  color: #575a5e;
  text-align: right;
  font-family: Patua One;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.profile_inner_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit_profile_name_input:first-child {
  width: 100px;
  /* margin-left: 10rem !important; */
}

.edit_profile_name_input:last-child {
  width: 100px; /* Adjust as needed */
}
.d-flex-end {
  display: flex;
  justify-content: flex-end; /* Align items to the right end */
  align-items: center;
}
.edit_wechatid_input {
  width: 65% !important;
  background-color: #f4f4f4;
  border: 1px solid #575a5e !important;

  color: #575a5e;
  text-align: right;
  font-family: Patua One;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.profile_edit_btns {
  text-align: center;
  height: 2.5rem;
  border-radius: 13px !important;
  background: #575a5e !important;
  border: none;
  color: #d3d3d3 !important;
  font-family: Patua One;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.profile_edit_btns:hover {
  background-color: #d3d3d3 !important;
  color: #575a5e !important ;
  border: 1px solid #575a5e !important;
}
.edit_pic_change_image {
  /* text-align: center !important; */
  border: 1px solid #575a5e !important;
  height: 2.5rem;
  border-radius: 10px !important;
  /* margin-top: 4rem;
  margin-left: 5rem; */
  /* width: 10% !important; */
  color: #575a5e !important;
  background-color: #d3d3d3 !important;
  font-family: Patua One;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
.edit_pic_change_image:hover {
  border: 1px solid #575a5e !important;
  color: #d3d3d3 !important;
  background-color: #575a5e !important;
}
.img_div {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.profile_subheadings {
  color: #575a5e !important;
}

@media (max-width: 800px) {
  .profile_edit_btns {
    margin-right: 10px;
  }
  .edit_pic_change_image {
    margin-top: 2rem;
    margin-left: 0rem;
  }
  .profile_inner_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .edit_profile_name_input {
    width: 100% !important;
    color: #575a5e;
    text-align: center;
    font-family: Patua One;
    font-size: 18px !important;
    font-weight: 200;
    line-height: 0px;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .profile_subheadings {
    margin-bottom: 0px !important;
  }

  .edit_email_input {
    width: 80% !important;
    background-color: rgba(0, 155, 255, 0.2);
    border: 1px solid gainsboro;
    color: #4b4b4b;
    text-align: center;
    font-family: Patua One;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-left: 10px;
    margin-right: 10px;
  }
  .edit_wechatid_input {
    width: 80% !important;
    color: #000000;
    text-align: right;
    font-family: Patua One;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-left: 10px;
    margin-right: 10px;
  }
  .profile_inner_div_without_hr {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .profile_edit_btns {
    margin-right: 10px;
  }
  .edit_pic_change_image {
    margin-top: 2rem;
    margin-left: 0rem;
  }
  .profile_inner_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .edit_profile_name_input {
    width: 100% !important;
    color: #575a5e;
    text-align: center;
    font-family: Patua One;
    font-size: 18px !important;
    font-weight: 200;
    line-height: 0px;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .profile_subheadings {
    margin-bottom: 0px !important;
  }
  .sub-subheading {
    display: none;
  }
  .edit_email_input {
    width: 100% !important;
    background-color: rgba(0, 155, 255, 0.2);
    border: 1px solid gainsboro;
    color: #4b4b4b;
    text-align: center;
    font-family: Patua One;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-left: 10px;
    margin-right: 10px;
  }
  .edit_wechatid_input {
    width: 100% !important;
    color: #000000;
    text-align: center;
    font-family: Patua One;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-left: 10px;
    margin-right: 10px;
  }
}
