.contactbtn_div {
  margin-right: 9rem;
}
.img_dropdown {
  display: flex;
}
.nav_border {
  border: 2px solid #575a5e;
  border-right: none;
  border-left: none;
}
.img_div:hover {
  cursor: pointer;
}
.contactbtn {
  text-align: center;
  margin-top: 10px;
  margin-left: 20px !important;
  width: 110px;
  height: 40px;
  border-radius: 5px;
  background: #009bff;
  margin-left: 0.5rem;
  border: none;
}
.profileNavbar {
  /* margin-right: 0.5rem; */
  margin-left: 3rem;
}
.login_signup {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #575a5e;
  font-family: Patua One;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 2.16px !important;
}
.Login_link,
.sign_up_link {
  text-decoration: none;
}
.Login_link:hover {
  color: #006ab0 !important;
}
.sign_up_link:hover {
  color: #006ab0 !important;
}
/* .mainNav {
  overflow-x: hidden;
  overflow-y: auto;
} */
.login_signin_button button {
  padding: 5px 15px;
  color: #d3d3d3;
  text-decoration: none;
  border: 1px solid #575a5e;
  background-color: #575a5e !important;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  margin-right: 10px;
  letter-spacing: 2.16px !important;
}

.login_signin_button button:hover {
  background-color: #d3d3d3 !important;
  color: #575a5e;
}

.login_signup_button button {
  padding: 5px 10px;
  color: #575a5e;
  /* font-weight: bold; */
  text-decoration: none;
  background-color: #d3d3d3 !important;
  border: 1px solid #575a5e;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  margin-left: 10px;
  letter-spacing: 2.16px !important;
}

.login_signup_button button:hover {
  background-color: #575a5e !important;
  color: #d3d3d3;
}
.navhr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #575a5e;
  height: 1px;
}
