.educationHeading {
  color: #575a5e;
  font-family: Patua One;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.edit_education_btn {
  color: #575a5e;
  border: none;
  margin-left: 5rem;
  display: inline-flex;
  padding: 10px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #f4f4f4;
  border: 1px solid #575a5e;
}
.edit_education_btn:hover {
  background: #575a5e;
  color: #f4f4f4;
}
.education_detail {
  margin: 10px;
  color: #575a5e;
  text-align: justify;
  font-family: Patua One;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: 30.9px;
  margin-top: 2rem;
  letter-spacing: 1.5px;
}
.centered-container img {
  display: block;
  margin: 0 auto;
  max-height: 500px;
}
.education_modal_title {
  width: 100%;
  font-family: Patua One;
}
.education_title_Model {
  border: none !important;
  border-radius: 10px !important;
  background: #f4f4f4 !important;
  color: #575a5e;
  font-family: Patua One;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 18px !important;
}
.education_title_Model:focus {
  box-shadow: 0 0 10px #575a5e !important;
}
.education_model_description {
  color: #575a5e !important;
  font-family: Patua One;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 200 !important;
  line-height: normal;
}
/* styles.css */

/* Set the minimum and maximum height for the quill editor */
.ql-editor {
  font-size: 16px;
  min-height: 250px; /* Adjust as needed */
  max-height: 250px; /* Adjust as needed */
}

/* Change the border color */
.ql-container {
  border: 1px solid #575a5e !important; /* Replace with your desired color */
}

.dropzone {
  border: 2px dashed #575a5e;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 2rem;
}
.education_img_size {
  object-fit: fill;
  border-radius: 8px;
  height: 55vh !important;
  width: 100% !important;
}
@media (max-width: 767px) {
  .education_img_size {
    object-fit: fill;
    border-radius: 8px;
    height: 35vh !important;
    width: 100% !important;
  }
}

.selected-image {
  max-width: 100%;
  margin-top: 10px;
}
.uploadImg_p {
  color: #0f0f0f;
  text-align: center;
  font-family: Patua One;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 24px !important;
  margin-top: 10px;
}
.uploadImg_b {
  color: var(--Primary, #575a5e);
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 24px;
  text-decoration-line: underline;
  margin-left: 5px !important;
}
.education_data_save {
  margin-top: 2rem;
  border-radius: 8px !important;
  background: var(--Primary, #575a5e) !important;
  border-color: none !important;
  width: 100%;
  color: #f4f4f4;
  border: 1px solid #575a5e;
  text-align: center;
  font-family: Patua One;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 200 !important;
  line-height: 31px !important;
  letter-spacing: 0.4px !important;
}
.model_textarea {
  background-color: #f4f4f4;
}
.education_data_save:hover {
  background: #d3d3d3 !important;
  color: var(--Primary, #575a5e) !important;
}
/* Center the toolbar */
.ql-toolbar {
  text-align: center;
  background-color: #d3d3d3 !important;
}

/* Style the toolbar buttons */
.ql-toolbar button {
  margin: 0 5px; /* Add some space between buttons */
}
.everyHeading {
  color: #575a5e;
  text-align: center;
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
}
.headingArrow {
  height: 0.7rem !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.center-content img,
.center-content p {
  margin: 0; /* Remove default margin to center properly */
}

@media only screen and (max-width: 767px) {
  .headingArrow {
    display: none !important;
  }
}
