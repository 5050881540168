.billing_payment_method_heading {
  color: #000;
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.show_card_detail {
  border: 1.5px solid var(--Text-shad-color, #9a9ab0);
  border-radius: 10px;
  width: 40%;
}
.master_billing_card {
  padding: 10px;
}
.billing_card_no {
  color: var(--Text-shad-color, #9a9ab0);
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 20px;
}
.delete_card_detail {
  text-align: center;
  color: #fff !important;
  font-family: Patua One;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  border-radius: 6.494px;
  background: #009bff !important;
  height: 5%;
  margin-top: 10px;
  margin-right: 1rem;
}
.delete_card_detail:hover {
  color: #009bff !important;
  background: #fff !important;
  border-color: #009bff !important;
}
.transaction_history_heading {
  color: #575a5e;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.invoice_download_btn {
  color: #575a5e !important;
  font-family: Patua One;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  padding: 10px 16px !important;
  align-items: center;
  border-radius: 8px !important;
  background: #d3d3d3 !important;
}
.invoice_download_btn:hover {
  background: #575a5e !important;
  color: #d3d3d3 !important;
  border-color: #575a5e !important;
}
.invoice_table_heading {
  border-radius: 10.5px 10.5px 0px 0px;
  background: #d3d3d3;
  color: #575a5e;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.invoice_table {
  width: 100%;
  border-collapse: collapse;
}

.invoice_table td {
  border: 1px solid #575a5e;
  padding: 10px;
}
.invoice_table th {
  padding: 10px;
}

.invoice_table .billing_invoice_tr {
  height: 70px;
}
.billing_data {
  color: #000;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.table_heading_size {
  font-size: 26px;
}
