.slider_container {
  height: 50vh;
  object-fit: cover;
}
.blue_div {
  background: #575a5e;
  width: 100%;
  height: 5vh;
  align-items: center;
  justify-content: center;
}
.blue_div_inner {
  justify-content: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.blue_p {
  color: #fff;
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.main_page_detail_div {
  margin-top: 8vh !important;
}
.main_page_detail {
  margin: 10px;
  color: #575a5e;
  text-align: justify;
  font-family: Patua One;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: 46px;
  letter-spacing: 1.5px;
}

@media (max-width: 767px) {
  .main_page_detail {
    margin: 20px;
    color: #575a5e;
    text-align: justify;
    font-family: Patua One;
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 30px;
    letter-spacing: 1px;
  }
}

.text-center {
  text-align: center;
}

.subscribe-button {
  align-items: center !important;
  justify-content: center !important;
  border-radius: 8px;
  background: #575a5e !important;
  border-color: none !important;
  color: #d3d3d3 !important;
  font-family: Patua One;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  padding: 10px 150px !important;
  display: inline-block !important;
}
/* Media query for small screens */
@media only screen and (max-width: 767px) {
  .subscribe-button {
    padding: 10px 50px !important; /* Adjust the padding for small screens */
  }
  .slider_container {
    height: 30vh;
    object-fit: fill;
  }
}

.subscribe-button:hover {
  background: #d3d3d3 !important;
  color: #575a5e !important;
}
