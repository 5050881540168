.rescipt_Headline {
  color: #000;
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1rem;
}
.rescipt_detail_heading {
  color: var(--Primary, #009bff);
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1rem;
}
.rescipt_subheading {
  color: #000;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2rem;
}
.rescipt_subheading_detail {
  color: #000;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-top: 2rem;
}
.rescipt_subheading_detail_total {
  color: var(--Primary, #009bff);
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 2rem;
  background-color: #a4d6f8;
}
