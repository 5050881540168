.cardPayment_Headline {
  color: #000;
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 5%;
}
.payment_form_box {
  justify-content: right;
  border: none;
  width: 60%;
  margin-left: 35%;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
.payment_form_bill {
  width: 40%;
  height: 90%;
  background-color: #ebebeb34;
  border: 1px solid #8a8a8a;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 5%;
  margin-left: 20%;
}
.payment_input {
  margin-left: 4rem;
  margin-right: 5rem;
  border-radius: 10px;
  border: 1px solid var(--Primary, #009bff) !important;
  padding-left: 3rem !important;
  color: #000;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.payment_input_vouchar {
  margin-left: 2rem;
  margin-right: 3rem;
  font-size: 18px !important;
  font-weight: 500 !important;
  border-radius: 10px;
  border: 1px solid var(--Primary, #009bff) !important;
  padding-left: 3rem !important;
}
.payment_input_date {
  margin-left: 4rem;
  margin-right: 1rem;
  border-radius: 10px;
  border: 1px solid var(--Primary, #009bff) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding-left: 3rem !important;
}
.payment_input_cvv {
  margin-left: 1rem;
  margin-right: 5rem;
  border-radius: 10px;
  border: 1px solid var(--Primary, #009bff) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding-left: 3rem !important;
}

.input-with-logo-container {
  position: relative;
}
.input-group-append {
  position: absolute;
  left: 80%;
}

.input-container {
  position: relative;
  /* display: inline-block; */
}
.apply_btn {
  position: absolute;
  top: 1;
  right: 0;
  background-color: white;
  border: none;
  margin-right: 10%;
  height: 80%;
}
/* .img_logo {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: auto;
} */
.checkbox_payment {
  margin-left: 4rem;
  width: 30px;
  height: 30px;
}
.checkbox_labal {
  color: #000;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-left: 1rem;
}
.payment_pay_btn {
  width: 25%;
  height: 3rem;
  margin-left: 4rem;
  border-radius: 10px;
  border: none;
  color: white;
  background: var(--Primary, #009bff);
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.payment_pay_btn:hover {
  border: 1px solid var(--Primary, #009bff);
  color: var(--Primary, #009bff);
  background: white;
}
.bill_heading {
  color: #575757;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-top: 3rem;
  margin-left: 2rem;
}
.bill_amount {
  color: #000;
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 3rem;
  margin-left: 2rem;
}
.bill_sub_heading {
  color: #000;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* margin-top: 4rem; */
  margin-left: 2rem;
  margin-bottom: 2rem;
}
.bill_sub_heading_detail {
  color: #000;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  /* margin-top: 4rem; */
  text-align: right;
}
.bill_hr {
  margin-left: 3rem;
  margin-right: 3rem;
  border-top: 1px solid #000 !important;
}
.img_logo_bill {
  width: 35%;
  height: 40%;
  /* margin-top: 0.5rem; */
  margin-left: 6rem;
}
