.pricing_lines {
  color: var(--secondary, var(--Secondary, #231f20));
  text-align: center;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.package_one_div {
  /* margin: auto; */
  margin-left: 60% !important;
  width: 328px;
  min-height: 30vh;
  max-height: auto;
  border-radius: 12px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
  background-color: #d3d3d3;
  /* margin-left: 30%; */
}

.package_two_div {
  margin-right: 60%;
  width: 328px;
  min-height: 30vh;
  border: 1px solid rgb(200, 200, 200) !important;
  border-radius: 12px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
  background-color: #d3d3d3;
}
.package_first_line {
  margin-top: 15px;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 29px;
  text-transform: capitalize;
  color: #575a5e;
}
.package_hr {
  margin-left: 10%;
  margin-right: 10%;
  color: 2px solid #575a5e;
}
.plan_name_packages {
  color: #575a5e;
  font-family: Patua One;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  text-transform: capitalize;
}
.plan_duration {
  color: #575a5e !important;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 29px; /* 207.143% */
  text-transform: capitalize;
}
.package_price {
  color: #575a5e !important;
  font-family: Patua One;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px; /* 116.667% */
  text-transform: capitalize;
}
.chose_plan_btn {
  display: flex;
  width: 187px;
  padding: 10px 16px;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
  border-radius: 8px;
  font-size: 22px !important;
  font-family: Patua One !important;
  color: #d3d3d3 !important;
  background-color: #575a5e !important;
  margin-left: 0rem !important;
  border: 2px solid #575a5e !important;
}
.chose_plan_btn:hover {
  color: #575a5e !important;
  background-color: #d3d3d3 !important;
}
.package_tick {
  width: 18px !important;
  height: 18px !important;
  padding: 2.16px 0px 2.043px 0px;
}

@media only screen and (max-width: 768px) {
  .package_one_div {
    margin-left: auto;
    margin-right: auto;
    margin: auto !important;
  }
  .package_two_div {
    margin-right: auto;
    margin-left: auto;
    margin: auto !important;
  }
  .package_tick {
    display: none;
  }
}
