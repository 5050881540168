.img_div_back_ground {
  position: relative;
}

.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: blur(10px); /* Adjust the blur intensity as needed */
}

.img_div_back_ground {
  height: 100%;
  width: 100%;
}
.img_logo {
  margin-left: 5%;
  margin-bottom: 2%;
}
.logo_display {
  display: none;
}
.login_img {
  object-fit: contain;
  max-width: 90%; /* Ensure the image doesn't exceed the container width */
  max-height: 100%; /* Ensure the image doesn't exceed the container height */
  /* margin-top: 5%; */
}

.login_img_div {
  margin-left: 10%;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
.main_div {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
}
.screen-container-footer {
  overflow: hidden !important;
  background-color: #d3d3d3;
}
.screen-container {
  min-height: 80vh;
  overflow: hidden;
}
.screen-container-x {
  overflow-x: hidden;
  height: 90vh;
}
/* MainPage.css */

/* Hide scrollbar for Chrome, Safari, and newer Edge */
body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent; /* You can use a color matching your background */
}

body {
  overflow-y: scroll; /* Always show vertical scrollbar */
}

.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.first_container {
  min-height: 95vh;
  max-height: 150vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  height: 480px;
  width: 500px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.2);
  font-family: "Patua One", sans-serif;
  border-radius: 20px !important;
  /* padding: 20px; */
}
.login_text {
  margin-top: 30px;
  color: #575a5e !important;
}
.login_btn_style {
  background-color: #575a5e !important;
  color: #d3d3d3 !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.48px;
}
.login_btn_style:hover {
  background-color: #444444 !important;
}
.left_right_margin {
  margin-left: 2rem;
  margin-right: 2rem;
}
.bottom_margin {
  margin-bottom: 1rem;
}
.admin_heading {
  /* border-radius: 20px; */
  border-radius: 20px 20px 0px 0px;
  background: #d3d3d3;
  height: 70px;
}
.admin_h4 {
  color: #575a5e;
  text-align: center;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: 25px; /* 129.167% */
  letter-spacing: 0.48px;
  margin-top: 10px;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.text p {
  font-size: 13px;
  color: #bac4d4;
  font-weight: 700;
}

.input-text {
  position: relative;
  margin-top: 30px;
}

input[type="text"] {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 9px;
  border: 1px solid #e0e3e9;
  padding: 0 10px;
  padding-left: 30px;
  outline: 0;
  font-size: 14px;
}

input[type="password"] {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 9px;
  border: 1px solid #e0e3e9;
  padding: 0 10px;
  padding-left: 30px;
  padding-right: 27px;
  outline: 0;
  font-size: 14px;
}

.fa-envelope {
  position: absolute;
  left: 10px;
  cursor: pointer;
  top: 15px;
}

.fa-lock {
  position: absolute;
  left: 12px;
  cursor: pointer;
  font-size: 15px;
  top: 15px;
}

.input-text .fa-eye-slash {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 15px;
  cursor: pointer;
}

.fa-eye {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 15px;
  cursor: pointer;
}

.faEyeSlash {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 15px;
  cursor: pointer;
}

.buttons {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons button {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 9px;
  font-size: 12px;
  outline: 0;
  color: #d3d3d3;
  cursor: pointer;
  transition: all 0.5s;
  background: var(--Primary, #575a5e);
}

.buttons button:hover {
  background-color: #3d3d3d;
}

/* .forgot {
  margin-top: 30px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

forgot a {
  text-decoration: none;
} */

.warning {
  border: 1px solid red !important;
}

.type_password {
  border: 1px solid red !important;
}

@media (max-width: 800px) {
  .login_img_div {
    display: none;
  }
  .first_container {
    display: none;
  }
  .logo_display {
    display: block;
  }
  .container {
    min-height: 75vh;
  }
}
