.Date_Range_PaymentLog {
  margin-left: 1rem;
  border-color: #575a5e !important;
  font-family: Patua One;
  font-size: 16px;
  font-weight: 200;
}
.table_div_paymentlog {
  border: 1px solid #575a5e;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  min-height: 10vh;
  max-height: 80vh;
  overflow-y: auto;
}
