.privacyHeading {
  color: var(--00000050, rgba(0, 0, 0, 0.5));
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 145%;
  letter-spacing: 1.5px;
  margin-top: 0px !important;
}
.privacy_Detail {
  margin: 10px;
  color: #575a5e !important;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 1.5px;
  /* opacity: 0.5; */
}
.privacyHeadings {
  margin: 10px;
  color: #575a5e;
  font-family: Patua One;
  font-size: 30px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 1.5px;
}
@media (max-width: 767px) {
  .privacy_Detail {
    margin: 20px;
  }
}
