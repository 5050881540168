.main {
  overflow: hidden;
}
.ms-n5 {
  margin-left: -45px;
}
.searchBtn {
  height: 25px;
}

.search_bar::placeholder {
  color: #575a5e;
  font-family: "Patua One", sans-serif;
}
.search_bar {
  border: 1px solid #575a5e !important;
  color: #575a5e !important;
  font-family: "Patua One", sans-serif;
  height: 50px;
}
.add_guest_btn:hover {
  color: #d3d3d3;
  background-color: #575a5e;
}
.add_guest_btn {
  color: #575a5e;
  border: 1px solid #575a5e !important;
  background-color: #d3d3d3;
  border: none;
  font-family: Patua One;
  font-size: 16px;
  font-weight: 200;
  margin-left: 5rem;
  display: inline-flex;
  padding: 3px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--primary-10, rgba(97, 94, 240, 0.1));
}
.Date_Range {
  margin-left: 1rem;
  border-color: #575a5e !important;
  font-family: Patua One;
  font-size: 16px;
  font-weight: 200;
}
.margin_right_date {
  margin-right: 1rem;
}
.table_div {
  border: 1px solid #575a5e;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  min-height: 10vh;
  max-height: 60vh;
  overflow-y: auto;
}
.table_div::-webkit-scrollbar {
  width: 0.5em;
}

.table_div::-webkit-scrollbar-track {
  background-color: transparent;
}

.table_div::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.table_div::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.guest_modal {
  background-color: #d3d3d3 !important;
}
.table th,
.table td {
  border: none;
  text-align: center;
  margin-right: 5px !important;
  font-family: Patua One;
  font-size: 15px;
}

.table th {
  background-color: none;
  font-weight: bold;
}

.actionBTN {
  border: none;
  background-color: white !important;
}

/* Conditional styles based on content */
.status_data {
  color: white !important;
}

.Subscribed {
  color: #4bc0a2 !important;
  background: rgba(75, 192, 162, 0.15) !important;
}

.Unsubscribed {
  color: #ff3333 !important;
  background-color: rgba(255, 51, 51, 0.15) !important;
}
.normal {
  color: #4bc0a2 !important;
  background: rgba(75, 192, 162, 0.15) !important;
}
.vip {
  color: #009bff !important;
  background: rgba(0, 155, 255, 0.15) !important;
}

.lockOrDelt {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  align-items: left;
  text-align: left;
  z-index: 1;
  position: absolute;
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #fff;
}
.edit_button {
  width: 40px;
  background: #fff;
  border-color: #fff;
  border: none;
  color: black;
  font-family: Patua One;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.edit_button:hover {
  color: #009bff;
  font-weight: bold;
  font-size: 15px;
}

.delete-button {
  text-align: left;
  width: 55px;
  background: #fff;
  border: none;
  border-color: #fff;
  color: #db0a0a;
  font-family: Patua One;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.delete-button:hover {
  columns: #4d0202;
  font-weight: bold;
  font-size: 15px;
}
.password-toggle {
  margin-top: -35px;
  margin-left: 27rem;
}
.guest-eye-icon {
  position: static;
}
.addNewGuest {
  height: 47px;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: #d3d3d3 !important;
  border: 1px solid #575a5e !important;
  color: #575a5e !important;
}
.addNewGuest:hover {
  color: #d3d3d3 !important;
  background-color: #575a5e !important;
}
.backgroundColor {
  background-color: #d3d3d3 !important;
  color: #575a5e !important;
}
