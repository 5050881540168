/* Media query for small screens */
@media only screen and (max-width: 767px) {
  .profile_pic_msg {
    margin-left: 0px; /* Remove margin */
    margin-top: 5px !important;
  }
  .message_heading_upper {
    padding-left: 10px !important;
    padding-top: 0px !important;
    margin-bottom: 5px;
  }
  .message_box {
    max-width: 90% !important;
  }
  .messenger_name {
    margin-bottom: 0px;
  }
  .input-container {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .input_length {
    margin-left: 0px !important;
  }
  .send_message {
    width: 10%;
    margin-left: 0px !important;
  }
  .input_div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 0rem !important;
  }
  .time_show {
    font-family: "Patua One", sans-serif !important;
    margin-left: 1rem;
    font-size: 12px;
  }
}
@media only screen and (max-width: 800px) {
  .input_div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 0rem !important;
  }
}

.time_show {
  font-family: Patua One;
  text-align: right;
  margin-bottom: 0px;
}
.show_messages {
  font-family: Patua One;
  letter-spacing: 0.7px;
}
.message_heading_upper {
  font-family: Patua One;
  font-size: 18px;
  font-weight: 200;
  padding-left: 20px;
  padding-top: 30px;
}

.profile_pic_msg {
  width: 50px;
  height: 50px;
  margin-left: 3rem;
  margin-top: 1rem;
}
.see_messages {
  overflow: hidden !important;
  margin-top: 1rem;
  position: relative;
  min-height: 75vh;
  max-height: 75vh;
  border: 1px solid #575a5e;
  overflow-y: auto;
  box-shadow: #d3d3d3 0px 5px 15px;
}

.message_container {
  min-height: 55vh;
  max-height: 55vh;
  overflow: auto !important;
}

.message_container::-webkit-scrollbar {
  width: 5px;
}

.message_container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.message_container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.message_container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.send_message {
  background-color: #575a5e !important;
  margin-left: 5px !important;
  color: #f4f4f4 !important;
  border-color: #575a5e !important;
  border-radius: 5px;
}
.input_div {
  position: absolute;
  bottom: 0;
  width: 90%;
  background-color: #f0f0f0; /* Just for visual representation */
  /* padding: 10px !important; */
  /* padding-left: 2rem !important; */
  margin-left: 2rem;
  margin-bottom: 0px !important;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px !important;
}
.form-control-lg {
  width: 100%;
  padding-right: 40px;
  border: 1px solid #818080 !important;
  box-sizing: border-box;
  padding-right: 5% !important;
  padding-bottom: 10px;
  z-index: 4;
}

.label-with-icon {
  position: absolute;
  right: 2rem;
  cursor: pointer;
  z-index: 10;
}
.message_box {
  max-width: 60%;
  height: auto;
  border-radius: 10px;
  background: #d3d3d3;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-left: 2%;
  margin-bottom: 1%;
}
.message_box_container {
  overflow: hidden;
  max-width: 95%;

  height: auto;
  border-radius: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-left: 2%;
  margin-bottom: 1%;
}
.nameAndDelt {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  display: flex;
}

.input_length {
  margin-left: 0rem;
}
.input-with-padding::placeholder {
  /* Adjust the padding value as needed */
  padding-left: 30px;
}

.input-with-padding {
  /* Adjust the padding value as needed */
  padding-left: 30px;
  background-color: #f3f3f3;
}
.delt_icon {
  background: none;
  border: none;
  cursor: pointer;
  /* padding: 0; */
  margin-left: auto;
}
.messenger_name {
  font-family: Patua One;
  font-size: 16px;
  font-weight: 200 !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
}
/* C {
  text-align: right;
  margin-bottom: 0px;
  margin-top: 0px;
} */
.lock-icon {
  width: 24px;
  height: 24px;
  margin-left: 28%;
  margin-top: 5px;
}
.vertical-line {
  border-right: 1px solid #000;
  height: 20px;
  margin: 10px 10px;
}

.lockdata {
  color: #575757;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.loader {
  background-color: transparent !important;
}

.preview_file {
  max-width: 7% !important;
  max-height: 70px !important;
  margin-bottom: 0px !important;
  object-fit: fill !important;
  /* border: 1px solid; */
  margin-top: 5px;
  margin-left: 35px;
  border-radius: 8px;
  position: relative;
  overflow-y: auto;
  background-color: white; /* Set your background color */
  /* z-index: 1000; */
  overflow: hidden;
}

@media (max-width: 767px) {
  .preview_file {
    max-width: 15% !important;
    margin-left: 5px;
  }
  .message_container {
    min-height: 50vh;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden !important;
  }
}
@media (min-width: 2560px) {
  .preview_file {
    max-width: 15% !important;
    max-height: 150px !important;
    margin-left: 3rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .preview_file {
    max-width: 10% !important;
    margin-left: 15px;
  }
}

.preview_img {
  max-width: 100%;
  /* margin-left: 3rem; */
  padding-bottom: 5rem !important;
  margin-top: 0px;
  opacity: 0.6;
  position: relative;
}
.delete-icon {
  border: none;
  position: absolute;
  background-color: transparent;
  top: 0;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
  font-weight: normal;
}
.delete-icon:hover,
.delete-icon:focus {
  /* Add this style for hover and focus state */
  font-weight: bold;
  color: #820a0a;
  /* Add any other styles for focus, e.g., outline or box-shadow */
  outline: none; /* Remove default outline */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle box-shadow on focus */
}
.preview_file .file-content {
  position: relative;
  width: 100%;
  /* display: block; */
}
.preview_file img,
.preview_file video,
.preview_file audio,
.preview_file iframe {
  width: 100%;
  height: 50%;
  opacity: 0.6;
  overflow: hidden !important;
  object-fit: fill !important;
}
.dragging {
  background-color: #820a0a;
  border: 2px dashed blue !important;
  opacity: 0.5 !important;
  cursor: copy;
}

/* styles.scss or your style file */

.transparent-modal .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
}

.transparent-modal .modal-header,
.transparent-modal .modal-body,
.transparent-modal .modal-footer {
  background: transparent;
  border: none;
}

.transparent-modal .modal-dialog {
  max-width: 100%;
  margin: 1.75rem auto !important;
}

.transparent-modal .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
}

.transparent-modal .loader {
  width: 100px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  margin: auto;
}
