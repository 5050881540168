.about_detail {
  margin: 10px;
  color: #231f20;
  font-family: Patua One;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 1.5px;
}
.other_pages_img {
  object-fit: cover;
  height: 40vh !important;
  border-radius: 8px;
}
