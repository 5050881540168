.logo {
  margin-left: 3rem;
}
.navLink {
  margin-top: 20px;
  margin-left: 5%;
  color: #575a5e !important;
  font-family: Patua One;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 200 !important;
  line-height: normal;
  letter-spacing: 2.16px;
  border-bottom: 2px solid transparent; /* Default border color */
}
.first_navLink {
  margin-left: 20%;
}

/* Media query for small screens */
@media only screen and (max-width: 768px) {
  .navLink {
    margin-left: 5% !important;
  }
  .first_navLink {
    margin-left: 5% !important;
  }
}

.navLink:hover {
  color: #000000 !important;
  background-color: #d3d3d3;
}
.navDropdown .dropdown-toggle {
  color: #575a5e !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
}
/* .navDropdown:hover .dropdown-menu {
  display: block;
} */
.navDropdown .dropdown-toggle:hover {
  color: #000000 !important;
}
.loginOrSignup {
  margin-left: 4rem;
}
.navDropdown {
  /* margin-top: 16px; */
  margin-left: 5%;
}
.navDropdown .dropdown-menu .dropdown-item:hover {
  color: #000000 !important; /* Your desired text color on hover */
}
/* .navDropdown .dropdown-toggle::after {
  display: none;
} */
.dropdown_item {
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  color: #575a5e !important;
}
.dropdown_item:hover {
  color: #2c2c2c !important;
  background-color: #d3d3d3 !important;
}
.dropdown_item_profile {
  color: #575a5e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Patua One;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 200% */
  letter-spacing: -0.18px;
}
.dropdown_item_profile:hover {
  color: #000000 !important;
  background-color: #d3d3d3 !important;
}
/* .dropdown_item_profile {
  margin-left: 1rem;
} */
.bg-custom-1 {
  background-color: #85144b;
}
.icon_right_margin {
  margin-right: 10px;
}
.bg-custom-2 {
  background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
}
.profile_pic {
  margin-top: 6px;
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%;
  /* right: 70px; */
}
.ProfileRight {
  right: 70px;
}
.profile_pic_div {
  margin-top: 4px;
  width: 65px;
  height: 65px;
  position: absolute;
  right: 5rem;
}

.profile_dropDown {
  float: right;
  margin-left: 50px;
}
.profileName {
  top: 10px;
  margin-left: 0px;
  font-family: Patua One;
  font-size: 18px !important;
  font-weight: 200 !important;
}

.top-right-container {
  display: flex;
  justify-content: flex-end; /* This aligns the container to the right */
  align-items: flex-end; /* Vertically aligns the content */
  height: 100%; /* Optional: Set the height as needed */
}
.profile_icons {
  margin-right: 10px;
}

.my_profile_icon {
  margin-left: 5px;
}
/* Media query for small screens */
@media only screen and (max-width: 768px) {
  .my_profile_icon {
    margin-top: 24% !important;
  }
  .profile_pic_div {
    position: absolute;
    right: 3rem;
  }
  .login_signup {
    flex-direction: column;
  }

  .profile_dropDown {
    float: right;
    margin-left: 0px;
  }
  .loginOrSignup {
    margin-left: 10rem;
    margin-right: 5rem;
  }
  .login_signup_button button {
    margin-right: 20px;
  }

  @media only screen and (max-width: 766px) {
    .my_profile_icon {
      margin-top: 2.7rem !important;
    }
    .loginOrSignup {
      margin-left: 12rem;
    }
  }

  .my_profile_heading {
    /* margin-left: 1.5rem; */
    padding-left: 2.5rem !important;
  }
}

.my_profile_heading {
  /* margin-left: 1.5rem; */
  padding-left: 2.5rem !important;
}
