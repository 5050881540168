.forgetpassword_p {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 1rem;
  font-family: Patua One;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 200 !important;
  color: #575a5e !important;
}
.p_under_email_input {
  margin-top: 1rem;
  color: #575a5e !important;
  font-family: Patua One !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.bottom_margin_btn {
  margin-bottom: 2rem;
}
