.margin_top {
  margin-top: 3% !important;
}
.inactive_btn {
  color: #575a5e;
  font-family: Patua One;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  background-color: white;
  margin-right: 3rem;
}
.active_btn {
  color: #575a5e;
  font-family: Patua One;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  border-bottom: 2px solid #575a5e;
  background-color: transparent;
  margin-right: 3rem;
}
.active_btn:hover {
  color: #1e1e1e;
}
.inactive_btn:hover {
  color: #1e1e1e;
}
.profile_heading {
  color: #575a5e;
  font-family: Patua One;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.dd-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile_edit_btn {
  height: 2.5rem;
  border-radius: 13px !important;
  background: #575a5e !important;
  border: none;
  color: #d3d3d3 !important;
  font-family: Patua One;
  font-style: normal;
  line-height: normal;
}
.profile_edit_btn:hover {
  border: 1px solid #575a5e !important;
  color: #575a5e !important;
  background-color: #d3d3d3 !important;
}
.profile_edit_btn_password {
  text-align: center;
  border-radius: 13px !important;
  background: #575a5e !important;
  border: none;
  color: #d3d3d3 !important;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
}
.profile_edit_btn_password_link {
  text-align: center;
  border-radius: 13px !important;
  background: #575a5e !important;
  border: none;
  color: #d3d3d3 !important;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
}
.profile_edit_btn_password_link:hover {
  border: 1px solid #575a5e !important;
  color: #575a5e !important;
  background-color: #d3d3d3 !important;
}
.profile_edit_btn_password:hover {
  border: 1px solid #575a5e !important;
  color: #575a5e !important;
  background-color: #d3d3d3 !important;
}
.pencil_icon {
  margin-right: 1rem;
}

.edit_profile_pic {
  border: 2px solid #808080;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  padding: 5px;
}
.profile_subheadings {
  color: #575a5e;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.profile_subheadings_value {
  color: #575a5e;
  margin-right: 1rem;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.profile_inner_div {
  border-bottom: 2px solid #000; /* Set the color and thickness of the border */
  width: 40%;
  justify-content: space-between;
}
.profile_inner_div_without_hr {
  width: 40%;
}

@media (max-width: 767px) {
  .profile_subheadings {
    display: none;
  }
  .margin_top {
    margin-top: 5% !important;
    display: flex !important;
    flex-wrap: wrap;
  }
  .active_btn {
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    border-bottom: 0px solid #575a5e;
    background-color: transparent;
    margin-right: 0.5rem;
  }
  .inactive_btn {
    color: #575a5e;
    font-size: 16px;
    font-weight: 300;
    background-color: white;
    margin-right: 0.5rem;
  }
  .profile_heading {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
  }
  .profile_edit_btn {
    margin-right: 10px;
  }
  .center-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .profile_inner_div {
    border-bottom: 2px solid #000; /* Set the color and thickness of the border */
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
  .profile_subheadings {
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 5px;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .profile_subheadings_value {
    /* margin-right: 1rem; */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 5px;
  }
  .profile_inner_div_without_hr {
    width: 100%;
    margin-bottom: 10px;
  }
  .profile_edit_btn_password_link {
    margin-right: 10px;
  }
}
