.contactus_heading {
  color: var(--2-f-2-f-2-f, #2f2f2f);
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.5px;
}
.contactus_inner_div {
  width: 70%;
  margin-left: 15rem;
  text-align: justify;
}

/* Media query for small screens */
@media only screen and (max-width: 768px) {
  .contactus_inner_div {
    margin-left: 5rem;
  }
}

.contact_stock_logo {
  width: 30%;
  margin-bottom: 1rem;
}
.contactus_detail {
  color: #231f20;
  text-align: justify;
  font-family: Patua One;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: 30.9px;
  margin-bottom: 2rem;
  letter-spacing: 1.5px;
}
.customer_care_div {
  width: 70%;
  text-align: justify;
  margin-left: 10rem;
}
.customer_care_heading {
  color: var(--2-f-2-f-2-f, #2f2f2f);
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.customer_care_detail {
  color: #2f2f2f;
  font-family: Patua One;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.5px;
}
.contact_message_div {
  border-radius: 20px;
  width: 70%;
  background-color: #f5f5f5;
}
.contact_us_envelop {
  width: 22px;
  height: 18px;
  margin-right: 10px;
}
.contact_message_div_inner {
  width: 85%;
  border-radius: 15px;
  border: 1px solid #575a5e;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.06);
  background-color: #f5f5f5;
  margin: 3rem;
}
.send_message_heading {
  color: #575a5e;
  font-family: Patua One;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1.5rem;
  letter-spacing: 1.5px;
}
.contact_message_input {
  font-family: Patua One;
  letter-spacing: 1.5px;
  border-radius: 4px;
  border: 1px solid #575a5e !important;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.contact_us_input_div {
  margin-top: 2rem;
}
.message_inbox {
  width: 100%;
}
.contact_message_input::placeholder {
  font-family: Patua One;
  font-size: 16px;
  font-weight: 500;
  color: #575a5e;
  letter-spacing: 1.5px;
}
.message_inbox::placeholder {
  padding-left: 1rem;
}
.send_message_btn {
  background-color: #575a5e !important;
  color: #d3d3d3 !important;
  border: 2px solid #575a5e !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}
.send_message_btn:hover {
  background-color: #d3d3d3 !important;
  color: #575a5e !important;
}

.contact_us_upper_div {
  display: block;
}

@media only screen and (max-width: 768px) {
  .contact_us_upper_div {
    display: none;
  }
}

/* Media query for small screens */
@media only screen and (max-width: 767px) {
  .contact_us_input_div,
  .d-flex {
    flex-direction: column;
  }
  .contact_us_upper_div {
    display: none;
  }

  .contact_message_div {
    width: 90%;
  }

  .contact_message_input::placeholder {
    font-family: Patua One;
    font-size: 12px;
    font-weight: 300;
    color: #575a5e;
    letter-spacing: 1.5px;
  }
  .contact_message_input {
    font-family: Patua One;
    letter-spacing: 1.5px;
    border-radius: 4px;
    border: 1px solid #575a5e;
    margin-left: 0.5rem;
    margin-right: 1rem;
    padding-left: 2px !important;
    margin-bottom: 1rem;
    width: 90% !important;
  }

  .send_message_heading {
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-top: 1rem;
  }
  .contact_message_div_inner {
    width: 85%;
    border-radius: 15px;
    border: 1px solid #575a5e;
    box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.06);
    background-color: #f5f5f5;
    margin: 1rem;
  }
}
