@media (max-width: 767px) {
  .daimond_pic {
    padding: 5px !important;
  }
  .daimond_pic {
    /* margin: auto; */
    width: 50px;
    height: 50px;
  }
  .plan_name {
    color: #575a5e;
    font-family: Patua One;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 200;
    line-height: none;
    padding-top: 10px !important;
    margin-bottom: 0px;
  }
  .subscription_plan_canel_btn {
    background: #575a5e !important;
    color: #d3d3d3 !important;
    font-family: Patua One;
    font-style: none;
    /* font-weight: 100 !important; */
    line-height: none;
    margin: auto !important;
    margin-top: 10px !important;
    margin-right: 10px !important;
  }

  .amount_year {
    color: #575a5e;
    font-family: Patua One;
    font-size: 13px !important;
  }
  .monthOrYear {
    font-size: 12px !important;
  }
  .show_card_detail_cancel_div {
    border: 1.5px solid var(--Text-shad-color, #9a9ab0);
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    min-width: 300px !important;
    max-width: 300px !important;
    margin: auto;
  }
  .active_img {
    display: none;
  }
}

.monthOrYear {
  font-size: 18px !important;
}
.daimond_pic {
  padding: 20px;
}
.plan_name {
  color: #575a5e;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  padding-top: 25px;
  margin-bottom: 0px;
}
.amount_year {
  color: #575a5e;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-top: 0px;
}
.active_img {
  width: 70px !important;
  height: 24px !important;
  margin-top: 0rem !important;
  margin-left: 1rem;
}
.show_card_detail_cancel_div {
  border: 1.5px solid var(--Text-shad-color, #9a9ab0);
  display: flex;
  border-radius: 10px;
  min-width: 550px;
  max-width: 550px;
  justify-content: space-between;
}
.subscription_plan_canel_btn {
  background: #575a5e !important;
  color: #d3d3d3 !important;
  font-family: Patua One;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  margin-top: 2rem;
  margin-right: 1rem;
}
.subscription_plan_canel_btn:hover {
  background: #d3d3d3 !important;
  color: #575a5e !important;
  border-color: #575a5e !important;
}
.swal2-actions button {
  margin-right: 30px; /* Adjust the value to set the desired margin */
  margin-left: 30px;
}
