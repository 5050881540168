/* Add these styles to your CSS file */

.layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Set height to 100% of viewport height */
}

.nav-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure it appears above other elements */
}

.content-container {
  flex: 1;
  overflow-y: auto;
  padding-top: 5rem;
}
