.fa-user {
  position: absolute;
  left: 10px;
  cursor: pointer;
  top: 15px;
}
.fa-user1 {
  position: absolute;
  left: 55%;
  cursor: pointer;
  top: 15px;
}
.fa-envelope1 {
  position: absolute;
  left: 10px;
  cursor: pointer;
  top: 75px;
}
.warning {
  border: 1px solid red !important;
  color: red;
}
.custom-confirm-button-class {
  background-color: #575a5e !important;
  border-color: #575a5e !important;
  border: 2px solid #575a5e !important;
  color: #d3d3d3 !important;
}
.custom-confirm-button-class:hover {
  color: #575a5e !important;
  background-color: #d3d3d3 !important;
}
.swal-title {
  font-family: "Patua One", sans-serif;
  color: #575a5e !important;
}

.swal-text {
  font-family: "Patua One", sans-serif !important;
  color: #575a5e !important;
}
.main_logo {
  width: 70%;
}
