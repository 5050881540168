.top_margin_btn {
  margin-top: 4rem;
}
.login_btn_style2 {
  color: #fff;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.48px;
  height: 5vh !important;
}
.confrm_pass {
  color: #231f20 !important;
  /* H3 Regular 18 */
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
