.footer_hr {
  border-top: 5px solid #fff !important; /* Change the color to your desired color */
  margin: 10px 0 !important; /* Adjust the margin as needed */
}
.footer_logo {
  margin-left: 5rem;
}
.footer_mail {
  color: #575a5e;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  /* line-height: normal; */
  /* letter-spacing: 1.62px; */
  margin-left: 5px;
  margin-bottom: 0px !important;
  margin-right: 1rem;
}
.footer_said_mail {
  color: #fff;
  font-family: Patua One;
  font-size: 12px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 1.62px;
  margin-bottom: 0px !important;
}
.envelop_img {
  padding-bottom: 3px;
  width: 25px;
  height: 25px;
}

.footer_data {
  color: #fff;
  text-align: justify;
  font-family: Patua One !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px; /* 192.857% */
}

.email_div {
  display: flex;
  align-items: right;
  justify-content: right;
}
.leftParagraph {
  margin-left: 1rem;
  /* margin-top: 10px; */
  color: #575a5e;
  text-align: left;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Patua One !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 100 !important;
  line-height: normal;
  letter-spacing: 1.5px;
}
.leftlink {
  /* margin: 10px; */
  color: #575a5e;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Patua One !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  text-decoration: none;
}
.leftlink:hover {
  text-decoration: underline;
}
.center_text {
  /* margin: auto; */
  color: #575a5e;
  font-family: Patua One;
  font-size: 12px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  letter-spacing: 1.5px;
}
.center_text:hover {
  text-decoration: underline;
}
.mailToEmail {
  margin-right: 5rem;
}

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  padding-bottom: 20px; /* Adjust the padding to leave space for the footer */
}

.content-container {
  flex: 1;
}

.footer {
  margin-top: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* Apply styles for tablet screens */
  .leftParagraph {
    font-size: 8px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .leftlink {
    font-size: 8px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    text-decoration: none;
  }
  .center_text {
    font-size: 8px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    text-decoration: none;
    letter-spacing: 0.5px;
  }
  .envelop_img {
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
  }
  .footer_mail {
    color: #575a5e;
    font-family: Patua One;
    font-size: 8px;
    font-style: normal;
    line-height: normal;
    font-weight: 100;
    margin-right: 1rem;
  }
}

@media (max-width: 767px) {
  .leftParagraph {
    margin: auto;
    font-size: 10px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
  }
  .leftlink {
    font-size: 10px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    text-decoration: none;
  }
  .center_text {
    font-size: 10px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    text-decoration: none;
    letter-spacing: 0.3px;
  }
  .email_div {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .envelop_img {
    display: none;
  }
  .footer_mail {
    color: #575a5e;
    font-family: Patua One;
    font-size: 10px;
    line-height: normal;
    font-weight: 100;
    margin: auto;
    /* margin-bottom: 0px !important; */
    /* margin-right: 1rem; */
  }
}
