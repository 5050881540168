.Forget_password_link {
  color: #575a5e;
  justify-content: center;
  text-align: center;
  font-family: Patua One;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 31px; /* 155% */
  letter-spacing: 0.4px;
}
.Forget_password_link:hover {
  color: #2d2d2d;
  font-weight: 450;
}
.Forget_password_div {
  color: #575a5e;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signUp_link {
  margin-left: 10px;
  color: #575a5e !important;
  font-size: 1.2em;
}
.signUp_link:hover {
  font-weight: 600;
}
